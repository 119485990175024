html, body, #root, #root > div, .ant-layout-content, .ant-layout-content > div, .ant-layout{
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

.ant-layout-header{
	color: #fff;

	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
}

.ant-layout-content{
	margin-left: 50px;
}

.ant-input-group-addon{
	margin-top: 5px;
	margin-bottom: 5px;
}

input, textarea {
	&.invalid {
		border: 1px solid red;
	}
}

.ant-input-group{
	&.invalid {
		input {
			border: 1px solid red;
		}
	}
}

.ant-slider{
	&.invalid {
		.ant-slider-rail {
			background: red;
		}
	}
}

.bottom-spacer{
	padding-bottom: 25px;
}

.receiver-status {
	margin: 0 auto;

	width: 50%;
}

.errorView {
	margin: 0 auto;

	width: 50%;

	code {
		background: #eee;
		padding: 5px;
	}

	button {
		width: 100%;
	}
}

footer {
	position: fixed;
    bottom: 0px;
    right: 0px;
    font-size: 16px;
    margin: 10px;
}