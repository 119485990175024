body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root, #root > div, .ant-layout-content, .ant-layout-content > div, .ant-layout {
  height: 100%;
  width: 100%;
  overflow-x: hidden; }

.ant-layout-header {
  color: #fff; }
  .ant-layout-header h1, .ant-layout-header h2, .ant-layout-header h3, .ant-layout-header h4, .ant-layout-header h5, .ant-layout-header h6 {
    color: #fff; }

.ant-layout-content {
  margin-left: 50px; }

.ant-input-group-addon {
  margin-top: 5px;
  margin-bottom: 5px; }

input.invalid, textarea.invalid {
  border: 1px solid red; }

.ant-input-group.invalid input {
  border: 1px solid red; }

.ant-slider.invalid .ant-slider-rail {
  background: red; }

.bottom-spacer {
  padding-bottom: 25px; }

.receiver-status {
  margin: 0 auto;
  width: 50%; }

.errorView {
  margin: 0 auto;
  width: 50%; }
  .errorView code {
    background: #eee;
    padding: 5px; }
  .errorView button {
    width: 100%; }

footer {
  position: fixed;
  bottom: 0px;
  right: 0px;
  font-size: 16px;
  margin: 10px; }

